import {
  Box,
  Hide,
  HStack,
  IconButton,
  Link,
  Show,
  Text,
  useBreakpointValue,
  VStack,
} from '@chakra-ui/react'
import { useFeatureIsOn, useFeatureValue } from '@growthbook/growthbook-react'
import { Button, Pagination } from '@opengovsg/design-system-react'
import { useEffect, useMemo, useState } from 'react'
import { BiPlus } from 'react-icons/bi'
import {
  Link as RouterLink,
  useNavigate,
  useSearchParams,
} from 'react-router-dom'

import { Loading } from '~components/Loading'
import { routes } from '~constants/routes'
import { useGetTemplates } from '~features/dashboard/hooks/dashboard.hooks'
import { REQUEST_NEW_TEMPLATE } from '~shared/constants/links'
import { DEFAULT_TEMPLATES } from '~shared/constants/templates'
import {
  GrowthBookFeatures,
  GrowthBookFeaturesMap,
} from '~shared/types/feature-flag'

import { EmptyTemplatesBody } from './components/EmptyTemplatesBody'
import { EmptyTemplatesSearchBody } from './components/EmptyTemplatesSearchBody'
import { TemplatesBody } from './components/TemplatesBody'
import { DebouncedSearchInput } from './DebouncedSearchInput'

export const PAGE_SIZE = 16
const DEFAULT_PAGE_SEARCH_PARAM = '1'

export const TemplatesPage = (): JSX.Element => {
  const [searchParams, setSearchParams] = useSearchParams()

  const isTemplateCreateOn = useFeatureIsOn(
    GrowthBookFeatures.templateCreate as string,
  )
  const navigate = useNavigate()
  const handleNavigate = () => {
    const path =
      defaultTemplates.length > 0
        ? `/${routes.admin.index}/${routes.admin.templates.index}/${routes.admin.templates.create}`
        : `/${routes.admin.index}/${routes.admin.templates.index}/${routes.admin.templates.edit}`

    navigate(path)
  }
  const [searchQuery, setSearchQuery] = useState('')

  const currentPage = useMemo(
    () =>
      parseInt(searchParams.get('currentPage') || DEFAULT_PAGE_SEARCH_PARAM) -
      1,
    [searchParams],
  )

  useEffect(() => {
    // This is to not affect other actions, such as editing on another page returning to first page
    if (searchQuery) {
      setSearchParams('')
    }
  }, [searchQuery]) // No searchParams dependency as we still want page navigation to work

  const templatesOffset = currentPage * PAGE_SIZE

  const {
    templates,
    isTemplatesLoading,
    count: totalCountWithSample,
  } = useGetTemplates({
    limit: PAGE_SIZE,
    offset: templatesOffset,
    searchQuery: searchQuery,
  })

  const { count: archivedCount } = useGetTemplates({
    limit: PAGE_SIZE,
    offset: currentPage * PAGE_SIZE,
    isArchived: true,
  })

  const buttonText = isTemplateCreateOn
    ? `Create new template`
    : `Request template`
  const handleOnClick = () => {
    if (isTemplateCreateOn) {
      navigate(
        `/${routes.admin.index}/${routes.admin.templates.index}/${routes.admin.templates.create}`,
      )
    } else {
      window.open(REQUEST_NEW_TEMPLATE)
    }
  }

  const defaultTemplates = useFeatureValue(
    GrowthBookFeatures.defaultTemplates as string,
    DEFAULT_TEMPLATES,
  ) as GrowthBookFeaturesMap[GrowthBookFeatures.defaultTemplates]

  if (isTemplatesLoading) {
    return <Loading fullscreen={true} />
  }

  return templates.length === 0 && searchQuery.length === 0 ? (
    <EmptyTemplatesBody buttonText={buttonText} handleOnClick={handleOnClick} />
  ) : (
    <VStack py={'50px'} align={'start'}>
      <HStack
        w={'100%'}
        mb={'15px'}
        paddingBottom={'36px'}
        justifyContent="space-between"
      >
        <VStack align={'left'}>
          {searchQuery.length ? (
            <>
              <Text textStyle={'h3'}>Search Results</Text>
              <Text>
                Showing {templates.length} of{' '}
                {totalCountWithSample ? totalCountWithSample - 1 : 0} templates
              </Text>
            </>
          ) : (
            <>
              <Text textStyle={'h3'}>Template Library</Text>
              <Text>Browse templates to create letters easily</Text>
            </>
          )}
        </VStack>
        <VStack>
          <HStack>
            <DebouncedSearchInput
              onChange={setSearchQuery}
              searchValue={searchQuery}
            />
            <Hide below="md">
              <Button
                leftIcon={<BiPlus fontSize="20px" />}
                justifyContent="start"
                onClick={handleNavigate}
                fontSize={'16px'}
                borderRadius={'8px'}
              >
                Create templates
              </Button>
            </Hide>
            <Show below="md">
              <IconButton
                icon={<BiPlus fontSize="20px" />}
                onClick={handleNavigate}
                fontSize={'16px'}
                borderRadius={'8px'}
                aria-label="Create templates"
              />
            </Show>
          </HStack>
        </VStack>
        {/* request button only appears if template create is not enabled */}
        {!isTemplateCreateOn && (
          <Button bg="interaction.main.default" onClick={handleOnClick}>
            {buttonText}
          </Button>
        )}
      </HStack>

      {searchQuery.length && templates.length == 0 ? (
        <Box w={'100%'}>
          <EmptyTemplatesSearchBody />
        </Box>
      ) : (
        <>
          <Box w={'100%'}>
            <TemplatesBody query={searchQuery} templates={templates} />
          </Box>
          <Box w={'100%'} justifyContent={'right'} pt="30px">
            {totalCountWithSample && (
              <Box float={'right'}>
                <HStack spacing={3}>
                  {archivedCount && archivedCount > 0 && (
                    <Link as={RouterLink} textDecoration="none" to={`archives`}>
                      See archived templates
                    </Link>
                  )}
                  <Pagination
                    totalCount={
                      searchQuery.length !== 0
                        ? totalCountWithSample - 1 // Don't include the sample template
                        : totalCountWithSample
                    }
                    pageSize={PAGE_SIZE}
                    onPageChange={(newPage) => {
                      setSearchParams((params) => {
                        if (newPage == 1) return {}
                        params.set('currentPage', newPage.toString())
                        return params
                      })
                      window.scrollTo(0, 0)
                    }}
                    currentPage={currentPage + 1}
                  />
                </HStack>
              </Box>
            )}
          </Box>
        </>
      )}
    </VStack>
  )
}
