import { Box, HStack, Text, Tooltip } from '@chakra-ui/react'
import { MdWarning } from 'react-icons/md'

import { CitizenNotificationMethod } from '~shared/dtos/letters.dto'
import { NotificationStatus } from '~shared/dtos/notifications.dto'
import { getRecipientNameToShowInBatchDetails } from '~shared/util/recipients'

interface RecipientRowProps {
  recipient: string
  notificationStatus: NotificationStatus | undefined
  recipientNotificationMethod?: CitizenNotificationMethod
}

export const RecipientRow = ({
  recipient,
  notificationStatus,
  recipientNotificationMethod,
}: RecipientRowProps) => {
  const notificationChannelString =
    recipientNotificationMethod === CitizenNotificationMethod.EMAIL
      ? 'email'
      : 'SMS'
  const isOnlineNotification =
    recipientNotificationMethod === CitizenNotificationMethod.EMAIL ||
    recipientNotificationMethod === CitizenNotificationMethod.SMS
  return (
    <HStack>
      {isOnlineNotification &&
        (notificationStatus === NotificationStatus.FAIL ||
          notificationStatus === NotificationStatus.INVALID_RECIPIENT) && (
          <Box position="relative" float="right" display="flex">
            <Tooltip
              label={`Delivery failed: The ${notificationChannelString} failed to reach the recipient.`}
              placement="right"
              fontSize={'sm'}
              width={'273px'}
              hasArrow
              backgroundColor={'grey.900'}
            >
              <div style={{ display: 'block', width: '100%' }}>
                <MdWarning
                  style={{
                    float: 'right',
                  }}
                  color="#C03434"
                />
              </div>
            </Tooltip>
          </Box>
        )}
      <Text color={'grey.400'}>
        {getRecipientNameToShowInBatchDetails(recipient)}
      </Text>
    </HStack>
  )
}
