import { Box, BoxProps } from '@chakra-ui/react'
import { forwardRef, useEffect, useRef } from 'react'

import { Loading } from '~components/Loading'
import { sanitizeHtml } from '~shared/util/html-sanitizer'
import { HEIGHT_A4, WIDTH_MOBILE } from '~utils/htmlUtils'

interface MobileLetterViewerProps extends BoxProps {
  html: string | undefined
  isLoading: boolean
  transformScale: number
}

export const MobileLetterViewerInner = ({
  html,
  isLoading,
  transformScale,
}: MobileLetterViewerProps): JSX.Element => {
  const iframeRef = useRef<HTMLIFrameElement>(null)
  const cleanHtml = sanitizeHtml(html || '')

  // dynamically gets height of content
  // since iframe does not have access to content, cannot use iframe height="auto"
  useEffect(() => {
    const iframe = iframeRef.current

    if (iframe) {
      iframe.onload = () => {
        const iframeDocument =
          iframe.contentDocument || iframe.contentWindow?.document
        if (iframeDocument) {
          const contentHeight = iframeDocument.body.scrollHeight
          // 44px is from top 22px + bottom 22px margin in mobile style, see shared/src/constants/templates.ts
          iframe.style.height = `${contentHeight + 44}px`
        }
      }
    }
  }, [cleanHtml])

  if (isLoading || !html) {
    return <Loading />
  }

  return (
    <Box
      transform={`scale(${transformScale})`}
      transformOrigin={'top'}
      width={WIDTH_MOBILE}
      bg="white"
      overflowX="hidden"
      minH={HEIGHT_A4}
    >
      <Box
        as="iframe"
        id="letter-viewer"
        ref={iframeRef}
        width="100%"
        srcDoc={cleanHtml}
        style={{
          border: 'none',
        }}
        scrolling="no"
      />
    </Box>
  )
}
export const MobileLetterViewer = forwardRef(MobileLetterViewerInner)
