import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  Button,
  Divider,
  Flex,
  FormControl,
  HStack,
  Image,
  ListItem,
  OrderedList,
  Radio,
  RadioGroup,
  Spacer,
  Text,
  VStack,
} from '@chakra-ui/react'
import { useFeatureValue } from '@growthbook/growthbook-react'
import { Badge } from '@opengovsg/design-system-react'
import { useFormContext } from 'react-hook-form'

import Singpost from '~/assets/Singpost.svg'
import { useCreatePdfPreview } from '~features/create/hooks/create.hooks'
import { ADDRESS_KEYS } from '~shared/constants/letters'
import { CitizenNotificationMethod } from '~shared/dtos/letters.dto'
import { GrowthBookFeatures } from '~shared/types/feature-flag'

import { BulkLetterIssueFormState } from '../../states/BulkLetterIssueFormState'
import { BulkIssueCardContainer } from '../BulkIssueCardContainer'
import { SendSampleForm } from '../SendSampleForm'

export const BulkIssueSettingsCard = () => {
  const { register, watch, setValue } =
    useFormContext<BulkLetterIssueFormState>()
  const citizenNotificationMethod = watch('citizenNotificationMethod')
  const selectedTemplate = watch('selectedTemplate')
  const { createPdfPreview, isPdfPreviewLoading } = useCreatePdfPreview()
  // Get the value then split by ','
  const ptvTemplateIdList = useFeatureValue(
    GrowthBookFeatures.ptvTemplateIdList,
    '',
  ).split(',')
  const canCreatePhysical =
    selectedTemplate &&
    ADDRESS_KEYS.every((key) => selectedTemplate.fields.includes(key)) &&
    ptvTemplateIdList.includes(selectedTemplate.id.toString())

  return (
    <BulkIssueCardContainer>
      <FormControl isInvalid={citizenNotificationMethod == null}>
        <Box mx={'2px'}>
          <RadioGroup
            {...(register('citizenNotificationMethod'),
            {
              onChange: (newVal: CitizenNotificationMethod) => {
                if (newVal) {
                  setValue('citizenNotificationMethod', newVal)
                }
              },
            })}
            value={citizenNotificationMethod}
          >
            <VStack spacing={6} align="left" pb={8}>
              <VStack spacing={2} align="left">
                <HStack spacing={4}>
                  <Text
                    textStyle="subhead-3"
                    fontSize={'14px'}
                    color={'grey.500'}
                  >
                    Send digitally via LetterSG
                  </Text>
                  <Spacer />
                  <Badge variant="subtle" borderRadius={'xl'}>
                    Recommended
                  </Badge>
                </HStack>
                <HStack spacing={3}>
                  <Radio
                    value={CitizenNotificationMethod.SMS}
                    size={'sm'}
                    width="104px"
                  >
                    <Text textStyle="body-1" pr={2}>
                      SMS
                    </Text>
                  </Radio>
                  <Radio
                    value={CitizenNotificationMethod.EMAIL}
                    size={'sm'}
                    width="212px"
                  >
                    <HStack>
                      <Text textStyle="body-1">Email</Text>
                    </HStack>
                  </Radio>
                </HStack>
              </VStack>
              {(citizenNotificationMethod === CitizenNotificationMethod.EMAIL ||
                citizenNotificationMethod ===
                  CitizenNotificationMethod.SMS) && <SendSampleForm />}
              {canCreatePhysical && (
                <>
                  <Divider />
                  <VStack spacing={2} align="left">
                    <HStack spacing={4}>
                      <Text
                        textStyle="subhead-3"
                        fontSize={'14px'}
                        color={'grey.500'}
                      >
                        Send physical via Singpost
                      </Text>
                      <Spacer />
                      <Badge
                        variant="subtle"
                        borderRadius={'xl'}
                        backgroundColor="transparent"
                      >
                        <Image src={Singpost} />
                      </Badge>
                    </HStack>
                    <Text textStyle="caption-1" color="gray.500">
                      Letters will be printed, sealed and sent via Singapore
                      Post.
                    </Text>
                    <HStack spacing={3}>
                      <Radio
                        value={CitizenNotificationMethod.PHYSICAL}
                        size={'sm'}
                      >
                        <Text textStyle="body-1">Physical letter</Text>
                      </Radio>
                    </HStack>
                    {/* Verify the button works by pushing to staging */}
                    {citizenNotificationMethod ===
                      CitizenNotificationMethod.PHYSICAL && (
                      <Button
                        variant={'outline'}
                        onClick={() =>
                          createPdfPreview({
                            html: selectedTemplate?.html || '',
                          })
                        }
                        isLoading={isPdfPreviewLoading}
                        width="fit-content"
                      >
                        Preview PDF
                      </Button>
                    )}
                  </VStack>
                </>
              )}
              <Divider />
              <VStack spacing={2} align="left">
                <Text textStyle="subhead-3" fontSize={'14px'} color="grey.500">
                  Send via other platform
                </Text>
                <Accordion borderColor={'transparent'} allowToggle>
                  <AccordionItem>
                    <Flex flexDirection="row">
                      <Text
                        maxWidth="80%"
                        mr={1}
                        textStyle="caption-1"
                        color="gray.500"
                      >
                        Customise, schedule and send digital letters via other
                        platforms.
                      </Text>
                      <AccordionButton
                        padding={0}
                        width="10%"
                        fontSize={'14px'}
                        color={'interaction.neutral.default'}
                        fontWeight={500}
                        _hover={{ backgroundColor: 'transparent' }}
                      >
                        <Text
                          color={'base.content.brand'}
                          textStyle="caption-1"
                        >
                          How?
                        </Text>
                        <AccordionIcon color={'base.content.brand'} />
                      </AccordionButton>
                    </Flex>
                    <AccordionPanel p={0} pt={2}>
                      <OrderedList pl={2} fontWeight={400} spacing={1}>
                        <ListItem textStyle="caption-1" color="gray.500">
                          Generate links for your letters on LetterSG
                        </ListItem>
                        <ListItem textStyle="caption-1" color="gray.500">
                          Send links out digitally via tools like Postman/SMS
                          Dome
                        </ListItem>
                      </OrderedList>
                    </AccordionPanel>
                  </AccordionItem>
                </Accordion>

                <Radio value={CitizenNotificationMethod.NONE} size={'sm'}>
                  <Text textStyle="body-1">Other services</Text>
                </Radio>
              </VStack>
            </VStack>
          </RadioGroup>
        </Box>
      </FormControl>
    </BulkIssueCardContainer>
  )
}
