import { StackProps, VStack } from '@chakra-ui/react'
import { useEffect, useRef, useState } from 'react'

import { LetterViewer } from '~features/editor/components/LetterViewer'
import { calculateTransformScale } from '~features/public/hooks/useTransformScale'

const FALLBACK_THUMBNAIL_WIDTH = 400

interface TemplatePreviewThumbnailProps extends StackProps {
  onClick?: () => void
  html: string
}

/*
 * Component for displaying template html in a thumbail preview
 * Currently used for template library and showing generated batches preview
 */

export const TemplatePreviewThumbnail = ({
  html,
  onClick,
  ...styleProps
}: TemplatePreviewThumbnailProps): JSX.Element => {
  const [templateCardWidth, setTemplateCardWidth] = useState<number>(
    FALLBACK_THUMBNAIL_WIDTH,
  )

  const ref = useRef<HTMLDivElement>(null)

  useEffect(() => {
    const handleWindowResize = () => {
      setTemplateCardWidth(ref.current?.offsetWidth ?? FALLBACK_THUMBNAIL_WIDTH)
    }
    handleWindowResize()
    window.addEventListener('resize', handleWindowResize)
  }, [ref.current])

  // set transform scale multiplier to 1, since we want template html to fit preview
  const transformScale = calculateTransformScale(templateCardWidth, 1)

  return (
    <VStack
      align={'center'}
      onClick={(event) => {
        event.preventDefault()
        onClick?.()
      }}
      overflow={'hidden'}
      ref={ref}
      {...styleProps}
    >
      <LetterViewer
        isLoading={false}
        html={html}
        transformScale={transformScale}
      />
    </VStack>
  )
}
