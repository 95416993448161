export const BULK_MAX_ROW_LENGTH = 500
export const MAXIMUM_EMAIL_RECIPIENTS = 2
export const PHONENUMBER_KEY = 'Phone Number'
export const ANCHOR_TEXT = 'OpenGovSg'
export const EMAIL_KEY = 'Email(s)'
export const ADDRESS_KEYS = [
  'block_house_no',
  'street_name',
  'building_name',
  'floor_unit_no',
  'postal_code',
]
export const SUPPORT_EMAIL = 'support@letters.gov.sg'
export const PLACE_HOLDER_PREFIX = '{{'
export const PLACE_HOLDER_SUFFIX = '}}'
export const LETTERSG_LOGO = 'https://file.go.gov.sg/inluvg.png'
