export const publicQueryKeys = {
  base: 'public' as const,
  letters: (letterPublicId: string) =>
    [publicQueryKeys.base, letterPublicId] as const,
}

export const adminQueryKeys = {
  whoAmI: 'who-am-i' as const,
  apiKeySuffix: 'api-key-suffix' as const,
  tinymce: 'tinymce-api-key' as const,
  govSgMetadata: 'govsg-metadata' as const,
  templates: ({
    templateId,
    searchQuery,
    limit,
    offset,
    isArchived,
  }: {
    templateId?: number
    searchQuery?: string
    limit?: number
    offset?: number
    isArchived?: boolean
  } = {}) => {
    switch (true) {
      case Boolean(searchQuery) && (Boolean(limit) || Boolean(offset)):
        return ['templates', searchQuery, limit, offset] as const
      case Boolean(templateId):
        return ['templates', templateId] as const
      case Boolean(isArchived) && (Boolean(limit) || Boolean(offset)):
        return ['templates', 'isArchived', limit, offset] as const
      case Boolean(limit) || Boolean(offset):
        return ['templates', limit, offset] as const
      default:
        return ['templates'] as const
    }
  },
  collaborators: (templateId?: number) =>
    templateId
      ? (['collaborators', templateId] as const)
      : (['collaborators'] as const),
  history: (templateId?: number) =>
    templateId ? (['history', templateId] as const) : (['history'] as const),
  batches: ({
    batchId,
    limit,
    offset,
  }: {
    batchId?: number
    limit?: number
    offset?: number
  } = {}) => {
    switch (true) {
      case Boolean(batchId):
        return ['batches', batchId] as const
      case Boolean(limit) && Boolean(offset):
        return ['batches', limit, offset] as const
      default:
        return ['batches'] as const
    }
  },
}
