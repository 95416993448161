import { ADDRESS_KEYS } from '../constants/letters'

export const jsonArrToCsvString = (
  jsonArr: { [key: string]: string }[],
  selectedKeys?: string[],
): string => {
  const replacerFn = (_key: string, value: string) =>
    value === null ? '' : value
  const headers = selectedKeys || Object.keys(jsonArr[0])
  const csv = jsonArr.map((row) =>
    headers.map((header) => JSON.stringify(row[header], replacerFn)).join(','),
  )
  csv.unshift(headers.join(','))
  const csvString = csv.join('\r\n')
  return csvString
}

export const extractAddressFields = (jsonArr: { [key: string]: string }[]) => {
  return (jsonArr as Record<string, string>[]).map((data) =>
    ADDRESS_KEYS.reduce((acc, key) => {
      if (key in data) {
        acc[key] = data[key]
      }
      return acc
    }, {} as Record<string, string>),
  )
}

export const extractFields = (
  parsedData: unknown[],
  fieldName: string,
): string[] => {
  const values: string[] = Array<string>(parsedData.length)
  ;(parsedData as Record<string, string>[]).map((row, index) => {
    values[index] = row[fieldName]
    delete row[fieldName]
  })

  return values
}
