import { Box, SimpleGrid, Text, useDisclosure, VStack } from '@chakra-ui/react'
import { useState } from 'react'

import { GetTemplateDto } from '~shared/dtos/templates.dto'

import { TemplatePreviewModal } from '../TemplatePreviewModal'
import { TemplateCard } from './TemplateCard'
interface TemplatesBodyProps {
  templates: GetTemplateDto[]
  isArchived?: boolean
  query?: string
}

export const TemplatesBody = ({
  templates,
  isArchived = false,
  query,
}: TemplatesBodyProps) => {
  const { isOpen, onOpen, onClose } = useDisclosure()

  const [initialIndex, setInitialIndex] = useState<number | null>(null)

  return (
    <Box w={'100%'}>
      <SimpleGrid columns={[1, 2, 3, 4]} gap={13} w={'100%'}>
        {templates.map((template, index) => (
          <Box key={index} w={'100%'}>
            <TemplateCard
              query={query}
              template={template}
              index={index}
              onClick={(index) => {
                onOpen()
                setInitialIndex(index)
              }}
            />
          </Box>
        ))}
      </SimpleGrid>
      {initialIndex !== null && (
        <TemplatePreviewModal
          isOpen={isOpen}
          onClose={() => {
            setInitialIndex(null)
            onClose()
          }}
          templates={templates}
          initialIndex={initialIndex}
          isArchived={isArchived}
        />
      )}
    </Box>
  )
}
