import { Box, Button, Text } from '@chakra-ui/react'
import { BiDownload } from 'react-icons/bi'

import { GetBatchDto } from '~shared/dtos/batches.dto'
import {
  CitizenNotificationMethod,
  GetLetterDto,
} from '~shared/dtos/letters.dto'
import { getRecipientNameToShowInBatchDetails } from '~shared/util/recipients'
import { jsonArrToCsv } from '~utils/csvUtils'
import {
  getLocaleTimeFromDate,
  parseDateV2,
  parseDateV2WithDay,
} from '~utils/dateUtils'
import { getLetterPublicLink } from '~utils/linkUtils'

export interface DownloadCsvReportButtonProps {
  batch: GetBatchDto
  showText?: boolean
  showIcon?: boolean
  size?: string
  variant: string
}

export const handleDownloadCsvReport = (batch: GetBatchDto) => {
  const { letters, templateName, createdAt, notificationMethod } = batch
  const downloadCsvName = `${templateName} [COMPLETED].csv`

  if (!letters) return

  const bulkLettersWithLink = letters.map((letter: GetLetterDto) => {
    // convert field values string into JSON object
    const letterParams = JSON.parse(letter?.fieldValues ?? '{}') as {
      [key: string]: string
    }
    const isPhysicalNotification =
      notificationMethod === CitizenNotificationMethod.PHYSICAL
    const isOnlineNotification =
      notificationMethod === CitizenNotificationMethod.EMAIL ||
      notificationMethod === CitizenNotificationMethod.SMS

    const recipients = isOnlineNotification
      ? letter.notifications?.map((notification) => notification.recipient)
      : isPhysicalNotification
      ? letter.notifications?.map((notification) =>
          getRecipientNameToShowInBatchDetails(notification.recipient),
        )
      : undefined

    const recipientsWithNotificationStatus =
      isOnlineNotification &&
      letter.notifications?.map(
        (notification) =>
          `${notification.recipient}-${notification.notificationStatus}`,
      )

    return {
      ...letterParams,
      ...(recipients && { recipients: recipients.join(';') }),
      ...(batch.notificationMethod === CitizenNotificationMethod.PHYSICAL
        ? {}
        : {
            read_status: letter.firstReadAt ? letter.firstReadAt : 'Unread',
          }),
      letters_link: `https://${getLetterPublicLink(letter.publicId)}`,
      date_of_issue: `${parseDateV2WithDay(createdAt)},${getLocaleTimeFromDate(
        createdAt,
      )}`,
      ...(recipientsWithNotificationStatus && {
        recipients_with_notification_status:
          recipientsWithNotificationStatus.join(';'),
      }),
    }
  })
  jsonArrToCsv(downloadCsvName, bulkLettersWithLink)
}

export const DownloadCsvReportButton = ({
  batch,
  showText = true,
  showIcon = true,
  variant,
}: DownloadCsvReportButtonProps): JSX.Element => {
  return (
    <Button
      variant={variant}
      onClick={(event) => {
        // this is to prevent clicks from propogating
        // to underlying components for e.g. batch table rows
        event.stopPropagation()
        handleDownloadCsvReport(batch)
      }}
    >
      {showIcon && <BiDownload size="1.5rem" />}

      {showText && (
        <>
          <Box paddingLeft="5px" />
          <Text
            overflow={'hidden'}
            textOverflow="ellipsis"
            whiteSpace={'nowrap'}
            color={variant === 'solid' ? 'standard.white' : 'brand.primary.600'}
          >
            Download CSV report
          </Text>
        </>
      )}
    </Button>
  )
}
