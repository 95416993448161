import { Image, Link, Text, VStack } from '@chakra-ui/react'
import { Link as RouterLink } from 'react-router-dom'

import EmptyTemplateLibrary from '~/assets/EmptyTemplateLibrary.svg'

export const EmptyTemplatesSearchBody = () => {
  return (
    <VStack spacing="32px" justify={'center'} height="70vh">
      <Image src={EmptyTemplateLibrary} mb="24px" />
      <VStack spacing="12px">
        <Text textStyle="h4" color="standard.black">
          No matching results
        </Text>
        <Text textStyle="body-1" color="grey.700">
          Try a different search or browse your achived templates
        </Text>
      </VStack>
      <Link
        as={RouterLink}
        textDecoration="none"
        color="brand.primary.500"
        textStyle={'subhead-1'}
        to={`archives`}
      >
        View archived templates
      </Link>
    </VStack>
  )
}
