import {
  InputGroup,
  InputLeftElement,
  InputRightElement,
} from '@chakra-ui/react'
import { Input } from '@opengovsg/design-system-react'
import debounce from 'lodash/debounce'
import { useCallback, useMemo, useState } from 'react'
import { BiSearch } from 'react-icons/bi'
import { MdClose } from 'react-icons/md'

type DebouncedSearchInputProps = {
  onChange: (val: string) => void
  searchValue: string
}

export const DebouncedSearchInput = ({
  onChange,
  searchValue,
}: DebouncedSearchInputProps): React.ReactElement => {
  const [tempSearchValue, setTempSearchValue] = useState(searchValue)

  const handleSearchInputChangeDebounced = useMemo(
    () => debounce(onChange, 500),
    [onChange],
  )

  const onSearchInputChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setTempSearchValue(event.target.value)
      handleSearchInputChangeDebounced(event.target.value)
    },
    [handleSearchInputChangeDebounced],
  )

  const clearSearch = useCallback(() => {
    setTempSearchValue('')
    onChange('')
    handleSearchInputChangeDebounced.cancel()
  }, [onChange, handleSearchInputChangeDebounced])

  return (
    <InputGroup>
      <InputLeftElement width="3rem">
        <BiSearch color="gray.300" />
      </InputLeftElement>
      <Input
        type="text"
        width={'100%'}
        fontSize="md"
        onChange={onSearchInputChange}
        value={tempSearchValue}
        placeholder="Search by name"
        pl="3rem"
        pr="3rem"
        borderRadius={'8px'}
      />
      {tempSearchValue && (
        <InputRightElement
          width="3rem"
          borderRadius={'100px'}
          _hover={{ cursor: 'pointer' }}
          onClick={clearSearch}
        >
          <MdClose color="gray.300" />
        </InputRightElement>
      )}
    </InputGroup>
  )
}
